import { ref } from "vue";
import { getDataRequest, getListRequest } from "@/sea/apis/BaseService";
import { AdministratorResponse } from "@/sea/apis/models/administrator/AdministratorResponse";

export const getAdministratorsRequest = () => {
  const {
    responseRef,
    errorRef,
    listRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    loadingRef,
    getList,
  } = getListRequest<AdministratorResponse>();

  const adminsRef = ref<Array<AdministratorResponse> | null | undefined>([]);

  const getAdministrators = async (
    search: string | null,
    isEnabled: boolean | null,
    sort: string | null,
    pageNumber: number,
    pageSize: number
  ) => {
    await getList(true, "/administrators", {
      params: {
        search: search,
        isEnabled: isEnabled,
        sort: sort,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
    adminsRef.value = listRef.value;
  };

  return {
    responseRef,
    errorRef,
    adminsRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    loadingRef,
    getAdministrators,
  };
};

export const getAdministratorRequest = () => {
  const { responseRef, errorRef, dataRef, getData } =
    getDataRequest<AdministratorResponse>();
  const administratorRef = ref<AdministratorResponse | null | undefined>(null);

  const getAdministrator = async (id: number) => {
    await getData(true, `/administrators/${id}`);
    administratorRef.value = dataRef.value;
  };

  return { responseRef, errorRef, administratorRef, getAdministrator };
};
