
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { useStore } from "vuex";
import { getAdministratorRequest } from "@/sea/apis/AdministratorsService";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const userId = store.getters.currentUser?.id;

    const { administratorRef, getAdministrator } = getAdministratorRequest();
    const loadingRef = ref(false);

    const fetchAdministrator = async (id: number) => {
      loadingRef.value = true;
      await getAdministrator(id);
      loadingRef.value = false;
    };

    const updateProfileDetailsButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      language: Yup.string()
        .required(
          I18nUtils.translate("sea.pages.account.fieldLanguageRequired")
        )
        .label("Language"),
    });

    const updatePasswordValidator = Yup.object().shape({
      currentPassword: Yup.string()
        .required(
          I18nUtils.translate("sea.pages.account.fieldCurrentPasswordRequired")
        )
        .label("Current password"),
      newPassword: Yup.string()
        .min(
          4,
          I18nUtils.translate("sea.pages.account.fieldNewPasswordNotLongEnough")
        )
        .required(
          I18nUtils.translate("sea.pages.account.fieldNewPasswordRequired")
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .min(
          4,
          I18nUtils.translate(
            "sea.pages.account.fieldConfirmNewPasswordNotLongEnough"
          )
        )
        .required(
          I18nUtils.translate(
            "sea.pages.account.fieldConfirmNewPasswordRequired"
          )
        )
        .oneOf(
          [Yup.ref("newPassword"), null],
          I18nUtils.translate(
            "sea.pages.account.fieldConfirmNewPasswordNotMatch"
          )
        )
        .label("Password Confirmation"),
    });

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updatePasswordButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: I18nUtils.translateGlobal(
              "sea.pages.account.msgPasswordChanged"
            ),
            icon: "success",
            confirmButtonText: I18nUtils.translateGlobal("sea.buttons.ok"),
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            passwordFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const updateProfileDetails = () => {
      if (updateProfileDetailsButton.value) {
        // Activate indicator
        updateProfileDetailsButton.value.setAttribute(
          "data-kt-indicator",
          "on"
        );

        setTimeout(() => {
          updateProfileDetailsButton.value?.removeAttribute(
            "data-kt-indicator"
          );
        }, 2000);
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("sea.pages.account.tabSettings", [
        "sea.pages.account.title",
      ]);
      await fetchAdministrator(userId);
    });

    return {
      I18nUtils,
      updateProfileDetailsButton,
      updateProfileDetails,
      profileDetailsValidator,
      passwordFormDisplay,
      updatePasswordValidator,
      updatePasswordButton,
      updatePassword,
      administratorRef,
      loadingRef,
    };
  },
});
